import React from "react"

import Layout from "../../components/Layout"
import RibbonText from "../../components/ui/RibbonText"
import Card from "../../components/ui/Card"
import { StaticImage } from "gatsby-plugin-image"
import TwoColCard from "../../components/ui/TwoColCard"
import Seo from "../../components/Seo"

export default function MeetTheTeam() {
  return (
    <Layout>
      <Seo title="Meet the Team" />
      <div className="wrapper container py-6 lg:py-12">
        <h1 className="!normal-case w-80 mb-8 lg:mb-16">Meet the Team</h1>
        <div className="flex flex-col space-y-12 lg:space-y-24 sm:items-center lg:w-3/4 mx-auto">
          <TwoColCard
            type="grid"
            col1={
              <Card>
                <h2>Lynette Manciameli</h2>
                <RibbonText
                  text="Co-Founder"
                  highlighted="Builder Finders Consultant"
                  justify="left"
                  className="my-3 md:my-6"
                />
                <p className="md:mt-6">
                  Lynette’s experience in building and property comes from many
                  sources. She grew up in a building family and she has been an active property investor for over 20
                  years. You could say that building and
                  property is in her blood. She is the analytical mind at
                  Builder Finders and she looks forward to finding and comparing
                  builders for you. When not running around with her three sons
                  she enjoys yoga, pilates and travelling.
                </p>
              </Card>
            }
            col2={
              <div>
                <StaticImage
                  src="../../images/lynette-manciameli.jpg"
                  alt="A photo of Lynette Manciameli in business attire standing on the docks."
                  className="h-full"
                  objectPosition="65% 50%"
                />
              </div>
            }
          />
          <TwoColCard
            className=""
            type="flex"
            col1={
              <>
                <div className="flex flex-col items-end hidden sm:inline">
                  <div className="sm:w-72">
                    <StaticImage
                      src="../../images/john-manciameli.png"
                      alt="A headshot photo of John Manciameli."
                      className="h-full max-h-72"
                      objectFit="contain"
                    />
                  </div>
                </div>
                <Card className="sm:hidden">
                  <h2>John Manciameli</h2>
                  <RibbonText
                    text="Co-Founder"
                    highlighted="Builder Finders Consultant"
                    justify="left"
                    className="my-3 md:my-6"
                  />
                  <p className="md:mt-6">
                    John has a passion for all facets of property. Whether it was a multi unit development, a brand new
                    home or renovating an existing one, John has seen what
                    has worked and what pitfalls to avoid. If John looks
                    familiar its because he has been a guest presenter on
                    Channel Ten and Sky News “Your Property Empire” talking all
                    things property and finance. When not looking after his 3
                    sons he loves Italian cars, plays that funny game called
                    squash, tries to fish whenever he can and loves to cook.
                  </p>
                </Card>
              </>
            }
            col2={
              <>
                <Card className="hidden sm:inline">
                  <h2>John Manciameli</h2>
                  <RibbonText
                    text="Co-Founder"
                    highlighted="Builder Finders Consultant"
                    justify="left"
                    className="my-3 md:my-6"
                  />
                  <p className="md:mt-6">
                    John has a passion for all facets of property. Whether it was a multi unit development, a brand new
                    home or renovating an existing one, John has seen what
                    has worked and what pitfalls to avoid. If John looks
                    familiar its because he has been a guest presenter on
                    Channel Ten and Sky News “Your Property Empire” talking all
                    things property and finance. When not looking after his 3
                    sons he loves Italian cars, plays that funny game called
                    squash, tries to fish whenever he can and loves to cook.
                  </p>
                </Card>
                <div className="flex flex-col items-end sm:hidden">
                  <div className="sm:w-72">
                    <StaticImage
                      src="../../images/john-manciameli.png"
                      alt="A headshot photo of John Manciameli."
                      className="h-full max-h-72"
                      objectFit="contain"
                    />
                  </div>
                </div>
              </>
            }
          />
          <TwoColCard
            className=""
            type="grid"
            col1={
              <Card>
                <h2>Jewelson Solis</h2>
                <RibbonText
                  text="Customer Service"
                  highlighted="IT"
                  justify="left"
                  className="my-3 sm:my-6"
                />
                <p className="sm:mt-6">
                  Jewelson is a man with many talents and the backbone of the
                  business. From managing the website, to systems and processes,
                  he is responsible for making sure the back end of Builder
                  Finders is fully operational. Jewelson is responsible for
                  collating the tenders, extracting the data and putting the
                  information into the Builder Finders information report.
                </p>
              </Card>
            }
            col2={
              <div>
                <StaticImage
                  src="../../images/jewelson-solis.jpg"
                  alt="A headshot photo of Jewelson Solis."
                  className="h-full max-h-72"
                  objectFit="contain"
                />
              </div>
            }
          />
          <TwoColCard
            className=""
            type="flex"
            col1={
              <>
                <div className="hidden sm:inline">
                  <StaticImage
                    src="../../images/love-centeno.jpg"
                    alt="A headshot photo of Love Centeno."
                    className="h-full sm:max-h-72 sm:w-56"
                    objectFit="contain"
                  />
                </div>
                <Card className="h-full sm:hidden">
                  <h2>Love Centeno</h2>
                  <RibbonText
                    text="Customer Service"
                    justify="left"
                    className="my-3 sm:my-6"
                  />
                  <p className="sm:mt-6">
                    Love is a very dedicated member of the Builder Finders team.
                    A lot of the creative ads, images and documents you have
                    come to now see and enjoy are a direct result of this
                    talented lady. Love is responsible for collating the
                    tenders, extracting the data and putting the information
                    into the Builder Finders information report.
                  </p>
                </Card>
              </>
            }
            col2={
              <>
                <Card className="h-full hidden sm:inline">
                  <h2>Love Centeno</h2>
                  <RibbonText
                    text="Customer Service"
                    justify="left"
                    className="my-6"
                  />
                  <p className="mt-6">
                    Love is a very dedicated member of the Builder Finders team.
                    A lot of the creative ads, images and documents you have
                    come to now see and enjoy are a direct result of this
                    talented lady. Love is responsible for collating the
                    tenders, extracting the data and putting the information
                    into the Builder Finders information report.
                  </p>
                </Card>
                <div className="sm:hidden flex flex-col items-center">
                  <StaticImage
                    src="../../images/love-centeno.jpg"
                    alt="A headshot photo of Love Centeno."
                    className="h-full max-h-72 w-56"
                    objectFit="contain"
                  />
                </div>
              </>
            }
          />
        </div>
      </div>
    </Layout>
  )
}
