import React from "react"

export default function TwoColCard({
  className,
  gap,
  space,
  col1,
  col2,
  type,
}) {
  return (
    <div className={className}>
      {type === "grid" ? (
        <div className={"grid grid-cols-1 sm:grid-cols-2 " + gap}>
          {col1}
          {col2}
        </div>
      ) : null}
      {type === "flex" ? (
        <div className={"flex flex-col sm:flex-row " + space}>
          {col1}
          {col2}
        </div>
      ) : null}
    </div>
  )
}

TwoColCard.defaultProps = {
  gap: "gap-4 sm:gap-16",
  space: "space-y-4 sm:space-x-16",
}
